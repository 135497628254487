<template>
  <div>
    <p class="m-0 p-0">
      {{ title }}
    </p>
    <h1 class="font-weight-bold">
      {{ prefix }} {{ value }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    prefix: {
      type: String,
      required: false,
      default: "GH¢"
    }
  },
};
</script>

