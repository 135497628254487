<template>
  <div class="d-flex align-items-end w-75" style="gap: 10px;">
    <div class="w-25">
      <p class="p-0">
        {{ title }}
      </p>
      <b-progress height="7px" :value="spendingRate" class="" :variant="progressVariant"></b-progress>
    </div>
    <div class="d-flex">
      <h1 class="font-weight-bold mb-0">
        {{ prefix }} {{ value }}
      </h1>
    </div>
    <div class="d-flex">
      <feather-icon
        icon="RefreshCwIcon"
        size="18"
        class="ml-1 cursor-pointer"
        @click="$emit('refreshDashboard')"
      />
      <div class="text-muted ml-1">
        Click here to refresh
      </div>
    </div>
  </div>
  </template>
  
<script>
  import {
    BProgress
  } from "bootstrap-vue"
  
  export default {
    components: {
      BProgress
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      value: {
        type: [String, Number],
        required: true,
      },
      prefix: {
        type: String,
        required: false,
        default: "GH¢"
      },
      spendingRate: {
        type: Number,
        required: true
      }
    },
    computed: {
      progressVariant() {
        return this.spendingRate >= 80 ? "danger" : "success";
      }
    }
  };
</script>
  
<style>
  [dir] .bg-success {
    background-color: #459042 !important;
  }
</style>
