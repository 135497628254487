<template>
  <b-overlay :show="loading">
    <h2>{{ getValueFromSource(currentUser, "partner_config_data.name") }}</h2>
    <div class="row ">
      <div class="d-flex flex-column ml-sm-1">
        <div class="d-flex align-items-center flex-wrap" style="gap: 20px">
          <data-display-card title="Start Amount" :value="formatMoney(getValueFromSource(dashboardStats, 'start_amount', 0))" />
          <data-display-card title="Total Funds" :value="formatMoney(getValueFromSource(dashboardStats, 'total_funds', 0))" />
          <data-display-card title="Total Spent" :value="formatMoney(getValueFromSource(dashboardStats, 'total_spent', 0))" />
          <data-display-card title="Attached Loans" :value="getValueFromSource(dashboardStats, 'number_of_attached_loans', 0)" prefix="#" />

          <data-display-progress-card 
            title="Running Balance" 
            :value="formatMoney(getValueFromSource(dashboardStats, 'balance', 0))"
            :spending-rate="spendingRate"
            @refreshDashboard="fetchDashboardStatsData"
          />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BOverlay,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import {
  get, round
} from "lodash";

import DataDisplayCard from './DataDisplayCard.vue'
import DataDisplayProgressCard from './DataDisplayProgressCard.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BProgress,
    BProgressBar,
    DataDisplayCard,
    DataDisplayProgressCard
  },
  data() {
    return {
      loading: false,
      dashboardStats: {}
    }
  },
  computed: {
    spendingRate() {
      const total_funds = this.getValueFromSource(this.dashboardStats, 'total_funds', 0)
      const start_amount = this.getValueFromSource(this.dashboardStats, 'start_amount', 0)
      const total_spent = this.getValueFromSource(this.dashboardStats, 'total_spent', 0)
      return round(total_spent / (total_funds + start_amount), 2) * 100
    }
  },
  created() {
    this.fetchDashboardStatsData();
  },
  methods: {
    async fetchDashboardStatsData(){
      try {
        this.loading = true; 

        const request = await this.useJwt().partnerService.fetchDashboardData({
          from: this.from,
          to: this.to
        });
        
        const { data: [ dashboard_data ] } = request.data;
        this.dashboardStats = dashboard_data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
