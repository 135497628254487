<template>
  <b-nav-item-dropdown
    left
    dropleft
    class="m-0"
    no-flip
    no-caret
  >
    <template #button-content>
      <feather-icon
        size="16"
        icon="LinkIcon"
        class="mr-50"
      />
    </template>

    <b-dropdown-item>
      <referral-link />
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, 
  BDropdownItem, 
} from 'bootstrap-vue'
import ReferralLink from "@/@core/components/shared/profile/referral-link.vue"


export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    ReferralLink
  },
  computed: {
    userName() {
      const full_name = this.getValueFromSource(this.currentUser, 'full_name');
      return this.sentenceCase(full_name);
    },
    userAvatar() {
      const image_path = this.getValueFromSource(this.currentUser, 'avatar.path');
      return image_path
    }
  }
}
</script>
