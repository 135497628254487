<template>
<div class="">

  <div class="navbar-container d-flex align-items-center w-100 justify-content-between">
    <!--left hand side-->
    <div class="flex-fill mr-5">
      <div class="d-lg-none">
        <b-link class="nav-link d-xl-none" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </div>

      <h1 v-if="isCurrentUserPartnerAdmin && $route.name === 'partner-home'">
        {{ getValueFromSource(currentUser, "partner_config_data.name") }}
      </h1>

      <h1 v-if="isCurrentUserOmcAdmin">
        {{ getValueFromSource(currentUser, "omc_account_data.name") }}
      </h1>

      <div v-if="isCurrentUserPartnerAdmin && $route.name !== 'partner-home'" class="d-none d-md-block">
        <partner-statistics />
      </div>

      <div v-if="isCurrentUserApproved && isCurrentUserClient" class="d-none d-md-block">
        <user-statistics />
      </div>

      <div v-else-if="!isCurrentUserApproved" class="d-none d-md-block">
        <div class="d-flex align-items-center justify-content-between w-100">
          <b-alert class="m-0 flex-fill" variant="danger" show>
            <div class="alert-body">
              <h2>Identity Verification</h2>
              <p style="font-size: 13px;">
                Your account is pending identity verification.
                We'll notify you by Email or SMS of your verification status. <br />
                Please contact support if your account is not verified within 48hrs.
              </p>
            </div>
          </b-alert>
        </div>
      </div>
    </div>

    <!-- right hand side-->
    <div class="pl-md-5 ml-md-5">
      <b-navbar-nav class="nav align-items-center ">
        <!-- <locale /> -->
        <referral-link-dropdown v-if="isCurrentUserClient" />
        <dark-Toggler class="d-lg-block" />
        <!-- <cart-dropdown /> -->
        <!-- <notification-dropdown /> -->
        <user-dropdown />
      </b-navbar-nav>
    </div>

  </div>

  <div v-if="!isCurrentUserApproved" class="mt-2 d-md-none">
    <b-alert class="m-0 flex-fill" variant="danger" show>
      <div class="alert-body">
        <h2>Identity Verification</h2>
        <p style="font-size: 13px;">
          Your account is pending identity verification.
          We'll notify you by Email or SMS of your verification status. <br />
          Please contact support if your account is not verified within 48hrs.
        </p>
      </div>
    </b-alert>
  </div>

  <div v-if="isCurrentUserApproved" class="navbar-container">
    <div>
      <div class="nav-item d-sm-block">
        <div class="d-flex align-items-center">
          <h3 style="color: #144F6A;">
            {{ getValueFromSource($route, 'meta.pageTitle', '') }}</h3>
        </div>
      </div>
    </div>

    <app-breadcrumb v-if="(y === 0) || (y > 0 && y <= 27)" />
  </div>

</div>
</template>

<script>
import {
  BLink,
  BAvatar,
  BNavbarNav,
  BProgress,
  BProgressBar,
  BAlert,
  BButton,
} from 'bootstrap-vue'
import {
  useWindowScroll
} from '@vueuse/core'

import UserStatistics from "@/pages/client/home/UserStatistics.vue";
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import PartnerStatistics from "@core/components/shared/partner/dashboard/PartnerStatistics.vue";

import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import Bookmarks from './components/Bookmarks.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import ReferralLinkDropdown from './components/ReferralLinkDropdown.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'

export default {
  components: {
    UserStatistics,
    PartnerStatistics,
    BLink,
    BAlert,
    BAvatar,
    BButton,
    BProgress,
    BProgressBar,
    AppBreadcrumb,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    ReferralLinkDropdown
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
  },
  setup() {
    const {
      y
    } = useWindowScroll()
    return {
      y
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
</style>
